import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import styles from "./NavBar.module.scss";
import Button from "../Button";
import { useResponsive } from "../../contexts/ResponsiveContext";
import HamburgerMenu from "../HamburgerMenu";
import { NavLink } from "react-router-dom";
import linkStyles from "../../components/LinkButton/LinkButton.module.scss";

const NavBarRight = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <div className={styles.navbarRight}>
      <NavLink className={linkStyles.linkBtn} to="/">
        home.{" "}
      </NavLink>
      <NavLink className={linkStyles.linkBtn} to="/quick-start">
        {" "}
        quick start.
      </NavLink>
      {!isAuthenticated && (
        <NavLink className={linkStyles.linkBtn} to="/sign-up">
          sign up.{" "}
        </NavLink>
      )}
      {isAuthenticated && (
        <NavLink className={linkStyles.linkBtn} to="/my-account">
          my account.{" "}
        </NavLink>
      )}
      {isAuthenticated ? (
        <Button
          text="logout."
          onClick={async () => {
            await logout();
            navigate("/");
          }}
          type="button"
          btnStyle="wire"
        />
      ) : (
        <NavLink className={linkStyles.linkBtn} to="/login">
          login.
        </NavLink>
      )}
    </div>
  );
};

const MobileMenu = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <HamburgerMenu>
      <NavLink className={linkStyles.linkBtnLight} to="/">
        Home
      </NavLink>
      <NavLink className={linkStyles.linkBtnLight} to="/quick-start">
        {" "}
        Quick start
      </NavLink>
      {!isAuthenticated && (
        <NavLink className={linkStyles.linkBtnLight} to="/sign-up">
          Sign up{" "}
        </NavLink>
      )}
      {isAuthenticated && (
        <NavLink className={linkStyles.linkBtnLight} to="/my-account">
          My account{" "}
        </NavLink>
      )}
      {isAuthenticated ? (
        <Button
          className={linkStyles.linkBtnLight}
          text="Logout"
          onClick={async () => {
            await logout();
            navigate("/");
          }}
          type="button"
          btnStyle="wire"
        />
      ) : (
        <NavLink className={linkStyles.linkBtnLight} to="/login">
          Login
        </NavLink>
      )}
    </HamburgerMenu>
  );
};

function NavBar() {
  const { isMobile } = useResponsive();

  return (
    <nav className={styles.navRoot}>
      <div className={styles.navbarContainer}>
        <div className={styles.navbarLeft}>
          <a href="/" className={styles.logo}>
            pohtia.
          </a>
        </div>
        {isMobile ? <MobileMenu /> : <NavBarRight />}
      </div>
    </nav>
  );
}

export default NavBar;
