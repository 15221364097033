import React, { useContext } from "react";

const COLORS = {
  blue: "#cbd9ea",
  red: "#f25b44",
};

type Theme = {
  primaryColor: string;
  secondaryColor: string;
};

type ThemeContextType = {
  theme: Theme;
};

const ThemeContext = React.createContext<ThemeContextType>({
  theme: {
    primaryColor: COLORS.blue,
    secondaryColor: COLORS.red,
  },
});

export const ThemeProvider = (props: {
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <ThemeContext.Provider
      value={{
        theme: {
          primaryColor: COLORS.blue,
          secondaryColor: COLORS.red,
        },
      }}
      {...props}
    />
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
