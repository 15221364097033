import { useEffect, useRef, useState } from "react";
import { Question, Stack as StackType } from "../types";
import { deleteStack, getAllQuestions, getStack } from "../api";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from "react-flexbox-grid";
import PageHeading from "../components/PageHeading";
import QuestionCard from "../components/QuestionCard";
import Input from "../components/Input";
import { useTheme } from "../contexts/ThemeContext";
import Loading from "../components/Loading";
import { debounce } from "lodash";
import GridWrapper from "../components/GridWrapper";

const CardList = ({ stackId }: { stackId: string }) => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const isInitialRender = useRef<boolean>(true);

  // Fetch all questions on initial render
  useEffect(() => {
    const fetchInitialQuestions = async () => {
      setLoading(true);
      try {
        const { data } = await getAllQuestions(stackId);
        setQuestions(data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInitialQuestions();
  }, [stackId]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    (async () => {
      setLoading(true);
      if (searchTerm.trim() === "") {
        const { data } = await getAllQuestions(stackId);
        setQuestions(data);
      } else {
        const { data } = await getAllQuestions(stackId, {
          searchTerms: searchTerm.split(" "),
        });
        setQuestions(data);
      }

      setLoading(false);
    })();
  }, [searchTerm, stackId]);

  return (
    <>
      <Col xs={12}>
        <Input
          placeHolder="Search questions"
          onChange={debounce(setSearchTerm, 300)}
        />
      </Col>

      {loading ? (
        <Loading />
      ) : questions.length === 0 ? (
        <EmptyState />
      ) : (
        questions.map(({ id, question, answer }) => (
          <QuestionCard
            key={id}
            stackId={stackId || ""}
            questionId={id}
            question={question}
            answer={answer}
          />
        ))
      )}
    </>
  );
};

const EmptyState = () => {
  const {
    theme: { primaryColor },
  } = useTheme();

  return (
    <div
      style={{ color: primaryColor, display: "flex", justifyContent: "center" }}
    >
      No questions
    </div>
  );
};

const Stack = () => {
  const [stack, setStack] = useState<StackType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { stackId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (stackId) {
        setLoading(true);
        const { data: stackData } = await getStack(stackId);
        setStack(stackData);
        setLoading(false);
      }
    })();
  }, [stackId]);

  if (loading || !stack || !stackId) return <Loading />;

  return (
    <GridWrapper>
      <PageHeading
        text={stack.name || ""}
        actions={[
          {
            text: "+ Study",
            disabled: stack.number_of_questions === 0,
            onClick: () => navigate(`/stacks/${stackId}/study`),
          },
          {
            text: "+ Add question",
            onClick: () => {
              navigate(`/stacks/${stackId}/questions/new`);
            },
          },
          {
            text: "- Delete stack",
            onClick: () => {
              if (!stack) return;
              // eslint-disable-next-line no-restricted-globals
              const confirmed = confirm(
                "Are you sure you want to delete this stack and all its questions?"
              );

              if (confirmed) {
                deleteStack(String(stack.id));
                navigate("/");
              }
            },
          },
        ]}
      />
      <CardList stackId={stackId} />
    </GridWrapper>
  );
};

export default Stack;
