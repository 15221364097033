import { Col, ColProps, Row } from "react-flexbox-grid";
import styles from "./Card.module.scss";
import { CSSProperties } from "react";
import GridWrapper from "../GridWrapper";

type CardType = "normal" | "slim";
type ActionType = {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  size?: number;
};
interface Props extends ColProps {
  body: JSX.Element | JSX.Element[];
  actions?: ActionType[];
  type?: CardType;
  style?: CSSProperties;
  className?: string;
}

const Title = ({
  title,
  subtitle,
  onClick,
}: {
  title: string;
  subtitle?: string;
  onClick?: () => void;
}) => {
  return (
    <Col xs={8}>
      <Row className={styles.titleRow}>
        <div>
          <span className={styles.titleText}>{title}</span>
          <span onClick={onClick} className={styles.edit}>
            edit
          </span>
        </div>
      </Row>
      <Row className={styles.subtitle}>{subtitle}</Row>
    </Col>
  );
};

const Body = ({
  children,
  cols,
  className,
}: {
  children: JSX.Element | JSX.Element[];
  cols?: number;
  className?: string;
}) => {
  return (
    <Col xs={cols || 8} className={`${styles.cardBody} ${className}`}>
      {children}
    </Col>
  );
};

const Actions = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  return <>{children}</>;
};

const Card = (props: Props) => {
  const style = props.type || "normal";

  return (
    <Col
      className={`${styles.cardContainer} ${styles[style]} ${props.className}`}
      style={props.style}
    >
      <Row
        className={`${styles.cardMainArea} ${
          props.actions ? "" : styles.noActions
        }`}
        start="xs"
      >
        {props.body}
      </Row>
      {props.actions && (
        <Row className={styles.actionsContainer} bottom="xs">
          {props.actions.map((a, idx) => {
            return (
              <Col key={idx} xs={a.size} className={styles.actionContainer}>
                <button
                  type="button"
                  className={styles.action}
                  onClick={a.onClick}
                  disabled={a.disabled}
                >
                  {a.text}
                </button>
              </Col>
            );
          })}
        </Row>
      )}
    </Col>
  );
};

Card.Title = Title;
Card.Body = Body;
Card.Actions = Actions;
export default Card;
