import { Oval } from "react-loader-spinner";
import styled from "styled-components";
import { useTheme } from "../contexts/ThemeContext";

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 1em;
`;

const Loader = () => {
  const {
    theme: { primaryColor },
  } = useTheme();
  return (
    <StyledWrapper>
      <Oval strokeWidth={5} color={primaryColor} secondaryColor="white" />
    </StyledWrapper>
  );
};

export default Loader;
