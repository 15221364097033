import { useEffect, useState } from "react";
import { Question } from "../types";
import { useNavigate, useParams } from "react-router-dom";
import { getAllQuestions, updateQuestionOnAnswer } from "../api";
import CustomMarkdown from "../components/CustomMarkdown";
import Button from "../components/Button/Button";
import styles from "./Study.module.scss";
import { Col, Row } from "react-flexbox-grid";
import GridWrapper from "../components/GridWrapper";
import StandoutCard from "../components/StandoutCard";
import Heading from "../components/Heading";
import Spacer from "../components/Spacer";
import LoadingSpinner from "../components/LoadingSpinner";

const QuestionView = ({ value }: { value: string }) => {
  return (
    <StandoutCard xs>
      <Row>
        <CustomMarkdown value={value} />
      </Row>
    </StandoutCard>
  );
};

const AnswerView = ({ value, show }: { value: string; show: boolean }) => {
  return (
    <>
      <Spacer size="0.5em" />
      <StandoutCard className={show ? styles.show : styles.hidden}>
        <CustomMarkdown value={value} className={styles.answerMarkdown} />
      </StandoutCard>
    </>
  );
};

const CompleteView = ({
  onRetry,
  onDone,
}: {
  onRetry: () => void;
  onDone: () => void;
}) => {
  return (
    <GridWrapper>
      <StandoutCard style={{ flexDirection: "column" }}>
        <Row center="xs">
          <Col xs style={{ padding: "6em" }}>
            <Heading text="🎉 All caught up!" />
          </Col>
        </Row>
      </StandoutCard>
      <Spacer size="0.5em" />
      <Row>
        <Col xs={6} className={styles.difficultyBtnContainer}>
          <Button text="Retry" color="black" onClick={onRetry} />
        </Col>
        <Col xs={6} className={styles.difficultyBtnContainer}>
          <Button text="Back to stacks" color="dark-grey" onClick={onDone} />
        </Col>
      </Row>
    </GridWrapper>
  );
};

const ButtonRow = ({
  stackId,
  question,
  isRevealed,
  setRevealed,
  onComplete,
}: {
  stackId: string;
  question: Question;
  isRevealed: boolean;
  setRevealed: (val: boolean) => void;
  onComplete: () => void;
}): JSX.Element => {
  if (isRevealed) {
    return (
      <>
        <StandoutCard xs style={{ flexDirection: "column" }}>
          <Row center="xs">
            <Heading text="How did you find the question?" type="s" />
          </Row>
          <Spacer size="0.5em" />

          <Row>
            <Col xs className={styles.difficultyBtnContainer}>
              <Button
                text="easy."
                color="green"
                onClick={() => {
                  const now: Date = new Date();
                  const nextWeek: Date = new Date(
                    now.getTime() + 7 * 24 * 60 * 60 * 1000
                  );
                  updateQuestionOnAnswer(
                    String(question.id),
                    stackId,
                    nextWeek.toISOString(),
                    "easy"
                  );
                  setRevealed(false);
                  onComplete();
                }}
              />

              <Spacer size="0.25em" />
              <Row center="xs">
                <small>
                  <i>see it again in 7 days</i>
                </small>
              </Row>
            </Col>
            <Col xs className={styles.difficultyBtnContainer}>
              <Button
                text="okay."
                color="dark-grey"
                onClick={() => {
                  const now: Date = new Date();
                  const tomorrow: Date = new Date(
                    now.getTime() + 24 * 60 * 60 * 1000
                  );

                  updateQuestionOnAnswer(
                    String(question.id),
                    stackId,
                    tomorrow.toISOString(),
                    "okay"
                  );
                  setRevealed(false);
                  onComplete();
                }}
              />
              <Spacer size="0.25em" />
              <Row center="xs">
                <small>
                  <i>see it again in 24 hours</i>
                </small>
              </Row>
            </Col>{" "}
            <Col xs className={styles.difficultyBtnContainer}>
              <Button
                text="hard."
                color="red"
                onClick={() => {
                  const now: Date = new Date();
                  const tenSecondsLater: Date = new Date(
                    now.getTime() + 10 * 1000
                  );

                  updateQuestionOnAnswer(
                    String(question.id),
                    stackId,
                    tenSecondsLater.toISOString(),
                    "hard"
                  );

                  setRevealed(false);
                  onComplete();
                }}
              />
              <Spacer size="0.25em" />
              <Row center="xs">
                <small>
                  <i>see it again in 30 seconds</i>
                </small>
              </Row>
            </Col>
          </Row>
        </StandoutCard>
      </>
    );
  } else {
    return (
      <Row center="xs">
        <Col xs>
          <Button
            style={{ width: "100%", padding: "1em" }}
            text={"Show answer"}
            color="red"
            onClick={() => {
              if (isRevealed) {
                setRevealed(false);
                onComplete();
              } else {
                setRevealed(true);
              }
            }}
          />
        </Col>
      </Row>
    );
  }
};

const StudyQuestion = ({
  stackId,
  question,
  onComplete,
}: {
  stackId: string;
  question: Question;
  onComplete: () => void;
}) => {
  const [isRevealed, setRevealed] = useState<boolean>(false);

  return (
    <Col xs className={styles.studyContainer}>
      <QuestionView value={question.question} />
      <AnswerView value={question.answer} show={isRevealed} />
      <Spacer size="2em" withLine />
      <ButtonRow
        stackId={stackId}
        question={question}
        isRevealed={isRevealed}
        setRevealed={setRevealed}
        onComplete={onComplete}
      />
    </Col>
  );
};

const Study = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [questionIdx, setQuestionIdx] = useState<number>(0);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const { stackId } = useParams();
  const navigate = useNavigate();

  if (!stackId) throw new Error("no stack ID");

  const shuffle = (cards: Question[]) => {
    let currentIndex = cards.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [cards[currentIndex], cards[randomIndex]] = [
        cards[randomIndex],
        cards[currentIndex],
      ];
    }

    return cards;
  };

  useEffect(() => {
    if (!stackId) return;
    (async () => {
      setLoading(true);
      const now = new Date();
      const { data } = await getAllQuestions(stackId, {
        date: now.toISOString(),
      });

      setQuestions(shuffle(data));
      setLoading(false);
    })();
  }, [stackId]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (isComplete || questions.length === 0) {
    return (
      <CompleteView
        onRetry={() => {
          setQuestions(shuffle(questions));
          setIsComplete(false);
          setQuestionIdx(0);
        }}
        onDone={() => navigate("/")}
      />
    );
  }

  return (
    <GridWrapper>
      {questions.length > 0 && (
        <StudyQuestion
          stackId={stackId}
          question={questions[questionIdx]}
          onComplete={() => {
            if (questionIdx < questions.length - 1) {
              setQuestionIdx(questionIdx + 1);
            } else {
              setIsComplete(true);
            }
          }}
        />
      )}
    </GridWrapper>
  );
};

export default Study;
