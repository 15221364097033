import { CSSProperties } from "react";
import styles from "./Button.module.scss";

type BtnStyle = "block" | "wire";

interface ButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  color?: "red" | "dark-grey" | "black" | "green";
  type?: "button" | "submit" | "reset";
  btnStyle?: BtnStyle;
  style?: {};
  icon?: string;
  className?: string;
}

const calcBtnStyle = (style: BtnStyle | undefined) => {
  switch (style) {
    case "wire":
      return styles.wireBtn;
    case "block":
    default:
      return styles.btn;
  }
};

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  type,
  disabled,
  btnStyle,
  style,
  color,
  icon,
  className,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={
        `${calcBtnStyle(btnStyle)} ${disabled ? styles.disabled : ""}` +
        " " +
        className
      }
      type={type}
      style={
        {
          "--btnColor": `var(--${color})`,
          ...style,
        } as CSSProperties
      }
    >
      <span className={styles.contentContainer}>
        {icon && <img className={styles.iconImg} src={icon} alt="text" />}
        {text}
      </span>
    </button>
  );
};

export default Button;
