import { useEffect, useState } from "react";

import {
  createQuestion,
  getQuestion,
  updateQuestion,
  deleteQuestion,
  createImage,
} from "../api";
import { useNavigate, useParams } from "react-router-dom";
import CustomMarkdown from "../components/CustomMarkdown";
import { Col, Row } from "react-flexbox-grid";
import styles from "./QuestionEditor.module.scss";
import Button from "../components/Button/Button";
import { toast } from "react-toastify";
import AWS from "aws-sdk";
import { useAuth } from "../contexts/AuthContext";
import Spacer from "../components/Spacer";
import GridWrapper from "../components/GridWrapper";
import TextInput from "../components/TextInput";
import Heading from "../components/Heading";
import StandoutCard from "../components/StandoutCard";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
});

const QuestionEditor = () => {
  const [question, setQuestion] = useState<string>("## My question");
  const [questionInputLoading, setQuestionInputLoading] =
    useState<boolean>(false);
  const [answer, setAnswer] = useState<string>("My answer");
  const [answerInputLoading, setAnswerInputLoading] = useState<boolean>(false);
  const { stackId, questionId } = useParams();
  const navigate = useNavigate();
  const { userId } = useAuth();

  useEffect(() => {
    if (!stackId || !questionId) return;
    (async () => {
      const { data } = await getQuestion(stackId, questionId);

      setQuestion(data.question);
      setAnswer(data.answer);
    })();
  }, [questionId, stackId]);

  const handleQuestionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setQuestion(event.target.value);
  };

  const handleAnswerChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setAnswer(event.target.value);
  };

  const handleSave = async () => {
    if (!stackId) return;

    if (!question || !answer) {
      toast("Must have question and answer");
      return;
    }

    if (questionId) {
      const { status } = await updateQuestion(
        questionId,
        stackId,
        question,
        answer
      );

      if (status === 200) {
        toast("Question updated!");
      }
    } else {
      const { status } = await createQuestion(stackId, question, answer);

      if (status === 200) {
        navigate(`/stacks/${stackId}`);
        toast("Question created!");
      }
    }
  };

  const handlePaste = async (
    event: React.ClipboardEvent<HTMLTextAreaElement>,
    type: string
  ) => {
    const items = event.clipboardData?.items;
    if (!items || !userId) return;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf("image") !== -1) {
        const blob = items[i].getAsFile();
        if (!blob) return;

        const reader = new FileReader();
        reader.onload = async (event) => {
          const base64String = event.target?.result as string;
          if (base64String) {
            if (type === "question") {
              setQuestionInputLoading(true);
            } else {
              setAnswerInputLoading(true);
            }
            const { data } = await createImage(userId, base64String);
            const domain =
              process.env.NODE_ENV === "development"
                ? "http://localhost:1337"
                : "https://api.pohtia.com";

            const imageMarkdown = `![Alt text](${domain}/api/images/${data.filename})`;
            if (type === "question") {
              setQuestion((prevMarkdown) => prevMarkdown + imageMarkdown);
              setQuestionInputLoading(false);
            } else {
              setAnswer((prevMarkdown) => prevMarkdown + imageMarkdown);
              setAnswerInputLoading(false);
            }
          }
        };

        reader.readAsDataURL(blob);
      }
    }
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmed = confirm("Are you sure you want to delete this question?");

    if (confirmed && stackId && questionId) {
      const { status } = await deleteQuestion(stackId, questionId);

      if (status === 200) {
        toast("Question deleted");
        navigate(`/stacks/${stackId}`);
      }
    }
  };

  return (
    <GridWrapper>
      <StandoutCard style={{ flexDirection: "column" }}>
        <Row top="xs" className={styles.questionHeadingRow}>
          <Heading text="Question" />
          {questionId && (
            <span className={styles.deleteAction} onClick={handleDelete}>
              - Delete question
            </span>
          )}
        </Row>
        <Spacer size="0.5em" />
        <Row>
          <TextInput
            loading={questionInputLoading}
            value={question}
            onChange={handleQuestionChange}
            onPaste={(e) => handlePaste(e, "question")}
            className={styles.questionArea}
            placeholder="Type you question markdown"
          />
        </Row>
        <Row>
          <Spacer size="1.5em" />
        </Row>
        <Row>
          <Heading text="Question Preview" />
        </Row>
        <Row>
          <Spacer size="0.5em" />
        </Row>

        <Row className={styles.previewRow}>
          <CustomMarkdown value={question} className={styles.markdownPreview} />
        </Row>
      </StandoutCard>
      <Spacer size="2em" withLine width="50%" />
      <StandoutCard style={{ flexDirection: "column" }}>
        <Row top="xs">
          <Heading text="Answer" />
        </Row>
        <Row>
          <Spacer size="0.5em" />
        </Row>

        <Row>
          <TextInput
            loading={answerInputLoading}
            value={answer}
            onChange={handleAnswerChange}
            onPaste={(e) => handlePaste(e, "answer")}
            className={styles.answerArea}
            placeholder="Type you answer markdown"
          />
        </Row>
        <Row>
          <Spacer size="1em" />
        </Row>

        <Row>
          <Heading text="Answer Preview" />
        </Row>
        <Row>
          <Spacer size="0.5em" />
        </Row>

        <Row className={styles.previewRow}>
          <CustomMarkdown value={answer} className={styles.markdownPreview} />
        </Row>
      </StandoutCard>
      <Row center="xs" className={styles.btnRow}>
        <Col xs>
          <Button text="Save" onClick={handleSave} color="red" />
        </Col>
      </Row>
    </GridWrapper>
  );
};

export default QuestionEditor;
